import '@fontsource/rubik';
import '@solana/wallet-adapter-react-ui/styles.css';
import { Elements } from '@stripe/react-stripe-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { WagmiProvider } from 'wagmi';
import App from './App.tsx';
import { config } from './config';
import './index.css';
import { STRIPE_PROMISE } from './paymentService.ts';
import ArConnectProvider from './providers/ArConnectProvider.tsx';
import GlobalProvider from './providers/GlobalProvider.tsx';
import SolanaProvider from './providers/SolanaProvider.tsx';

const queryClient = new QueryClient();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ArConnectProvider>
          <SolanaProvider>
            <GlobalProvider>
              <Elements stripe={STRIPE_PROMISE}>
                <App />
              </Elements>
            </GlobalProvider>
          </SolanaProvider>
        </ArConnectProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </StrictMode>,
);
