import { TurboUnauthenticatedClient } from '@ardrive/turbo-sdk/web';
import { useQuery } from '@tanstack/react-query';

const useFiatRates = (
  turboUnauthenticatedClient?: TurboUnauthenticatedClient,
) => {
  const res = useQuery({
    queryKey: ['fiatRates'],
    queryFn: () => {
      if (!turboUnauthenticatedClient) {
        throw Error('TurboUnauthenticatedClient is not set');
      }

      return turboUnauthenticatedClient.getFiatRates();
    },
  });
  return res;
};

export default useFiatRates;
