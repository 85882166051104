import {
  ArconnectSigner,
  TokenType,
  TurboSigner,
} from '@ardrive/turbo-sdk/web';
import { useWallet } from '@solana/wallet-adapter-react';
import { useCallback, useEffect, useState } from 'react';
import {
  useAccount,
  useConnectors,
  useDisconnect,
  useSignMessage,
} from 'wagmi';
import useArConnect from './useArConnect';

export type AddressState = {
  address: string;
  token: TokenType;
  disconnect: () => void;
  explorerUrl: string;
  signer?: TurboSigner;
};

const useAddress = (): AddressState | undefined => {
  const [addressState, setAddressState] = useState<AddressState>();

  const { address, disconnect: arweaveDisconnect } = useArConnect();
  const account = useAccount();

  const connectors = useConnectors();
  const { disconnectAsync: ethereumDisconnect } = useDisconnect();
  const { connected, publicKey, disconnect: solanaDisconnect } = useWallet();

  const { signMessageAsync } = useSignMessage();

  const updateAddressState = useCallback(async () => {
    if (address) {
      if (address === addressState?.address) {
        return;
      }
      setAddressState({
        address: address,
        token: 'arweave',
        disconnect: arweaveDisconnect,
        explorerUrl: `https://viewblock.io/arweave/address/${address}`,
        signer: new ArconnectSigner(window.arweaveWallet),
      });
    } else if (connected && publicKey) {
      // Need to first check for SOL address before doing check for ETH address,
      // since user can connect with Phantom which supports both ETH and SOL.

      if (publicKey.toBase58() === addressState?.address) {
        return;
      }
      setAddressState({
        address: publicKey.toBase58(),
        token: 'solana',
        disconnect: async () => {
          await solanaDisconnect();
        },
        explorerUrl: `https://solscan.io/address/${publicKey.toBase58()}`,
      });
    } else if (account?.address && account?.connector?.name == 'MetaMask') {
      if (account.address === addressState?.address) {
        return;
      }
      // const connector = connectors.find((c) => c.id === account?.connector?.id);

      // const provider = {
      //   getSigner: () => ({
      //     signMessage: async (message: unknown) => {
      //       const arg = message instanceof String ? message : { raw: message };

      //       return await signMessageAsync({
      //         message: arg as SignableMessage,
      //         account: account.address,
      //         connector: connector,
      //       });
      //     },
      //   }),
      // };
      // const signer = new InjectedEthereumSigner(provider);

      setAddressState({
        address: account?.address,
        token: 'ethereum',
        disconnect: async () => {
          const connector = connectors.find((c) => c.name === 'MetaMask');
          await ethereumDisconnect({ connector });
        },
        explorerUrl: `https://etherscan.io/address/${account?.address}`,
        // signer: signer as unknown as TurboSigner,
      });
    } else {
      setAddressState(undefined);
    }
  }, [
    account?.address,
    account?.connector?.name,
    address,
    addressState?.address,
    arweaveDisconnect,
    connected,
    connectors,
    ethereumDisconnect,
    publicKey,
    solanaDisconnect,
  ]);

  useEffect(() => {
    updateAddressState();
  }, [
    account,
    arweaveDisconnect,
    ethereumDisconnect,
    signMessageAsync,
    solanaDisconnect,
    updateAddressState,
  ]);
  return addressState;
};

export default useAddress;
