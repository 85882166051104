import './App.css';
import Header from './Header';
import LandingPage from './LandingPage';
import TopupPage from './TopupPage';
import useAddress from './hooks/useAddress';

function App() {
  const address = useAddress();

  const loggedIn = address !== undefined;

  return (
    <div className="mb-12 dark:text-[#f5f5f5]">
      <Header />
      {loggedIn ? <TopupPage /> : <LandingPage />}
    </div>
  );
}

export default App;
