import { useState } from 'react';
import WalletSelectionModal from './modals/WalletSelectionModal';

const LandingPage = () => {
  const [showWalletModal, setShowWalletModal] = useState(false);

  return (
    <div>
      <div className="flex w-full flex-col items-center rounded border border-default bg-canvas px-44 py-48">
        <div className="text-[3.25rem] font-bold">Welcome to Turbo</div>
        <div className="mt-8 text-xl">
          The fastest and most reliable way to get your data onto the{' '}
          <a
            className="underline"
            href="https://ar.io/articles/what-is-the-permaweb"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Permaweb"
          >
            permaweb
          </a>
          . Seamlessly fund permanent storage on Arweave with fiat or crypto and
          build the next big thing in web3.
        </div>
        <button
          className="mt-24 rounded bg-fg-muted px-4 py-2 font-bold text-black"
          onClick={() => {
            setShowWalletModal(true);
          }}
        >
          Connect Wallet
        </button>

        {showWalletModal && (
          <WalletSelectionModal
            onClose={() => setShowWalletModal(false)}
            message={''}
          />
        )}
      </div>
      <div className="text-link mt-6 flex w-full justify-center text-sm">
        By connecting your wallet you agree to our{' '}
        <a
          className="pl-1 underline"
          href="https://ardrive.io/tos-and-privacy"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="ArDrive terms and conditions"
        >
          Terms and conditions
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
