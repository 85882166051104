import { TurboUnauthenticatedClient } from '@ardrive/turbo-sdk/web';
import { useQuery } from '@tanstack/react-query';
import { GiB } from '../constants';

const useUploadCostGib = (
  turboUnauthenticatedClient?: TurboUnauthenticatedClient,
) => {
  const res = useQuery({
    queryKey: ['uploadCostGib', turboUnauthenticatedClient],
    queryFn: () => {
      if (!turboUnauthenticatedClient) {
        throw Error('TurboUnauthenticatedClient is not set');
      }
      return turboUnauthenticatedClient.getUploadCosts({ bytes: [GiB] });
    },
  });
  return res;
};

export default useUploadCostGib;
