import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import CopyButton from './components/CopyButton';
import useAddress from './hooks/useAddress';
import { TurboLogoIcon } from './icons';
import { useGlobalStore } from './store';
import { formatWalletAddress } from './utils';

const Header = () => {
  const address = useAddress();
  const clearAllPaymentState = useGlobalStore(
    (state) => state.clearAllPaymentState,
  );

  return (
    <div className="flex py-8">
      <TurboLogoIcon />
      <div className="grow" />
      {address && (
        <Popover className="relative">
          <PopoverButton className="flex items-center gap-2 rounded border border-default px-2 py-1.5 font-semibold">
            <div className="size-1 rounded-full bg-green-500" />
            <div className="text-fg-muted">
              {formatWalletAddress(address.address)}
            </div>
          </PopoverButton>

          <PopoverPanel
            anchor="bottom"
            className="mt-4 flex flex-col rounded bg-surface text-left  text-sm text-fg-muted shadow-lg drop-shadow-2xl"
          >
            <div className="flex items-center gap-4 border-b border-default px-6 py-5">
              <button
                className="grow font-bold"
                onClick={() => {
                  window.open(address.explorerUrl);
                }}
              >
                {formatWalletAddress(address.address, 7)}
              </button>
              <CopyButton textToCopy={address.address} />
            </div>
            <button
              className="px-6 py-5 font-semibold"
              onClick={() => {
                clearAllPaymentState();
                address.disconnect();
              }}
            >
              Disconnect
            </button>
          </PopoverPanel>
        </Popover>
      )}
    </div>
  );
};

export default Header;
