import Placeholder from './components/Placeholder';
import Tooltip from './components/Tooltip';
import useFiatRates from './hooks/useFiatRates';
import useFiatToAR from './hooks/useFiatToAR';
import { InfoIcon, LinkUpArrowIcon, RefreshIcon, TurboIcon } from './icons';
import { useGlobalStore } from './store';

const TurboCalloutPanel = ({ text }: { text: string }) => {
  return (
    <div className="text-high flex h-12 items-center gap-4 rounded border border-l-[.3125rem] border-default px-4 py-2.5 text-sm">
      <TurboIcon /> {text}
    </div>
  );
};

const InfoPanel = () => {
  // const [showMoreInfo, setShowMoreInfo] = useState(false);

  const turboUnauthenticatedClient = useGlobalStore(
    (state) => state.turboUnauthenticatedClient,
  );

  const {
    refetch,
    data: fiatRates,
    isRefetching,
  } = useFiatRates(turboUnauthenticatedClient);
  const {
    data: fiatToAR,
    refetch: refetchFiatToAr,
    isRefetching: isRefetchingFiatToAr,
  } = useFiatToAR(turboUnauthenticatedClient);

  return (
    <div className="flex size-full flex-col bg-surface p-12 text-left">
      <div className="flex">
        <div className="grow font-bold text-fg-muted">Info</div>
        <button
          className="flex items-center gap-2 text-sm text-fg-disabled"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open('https://ardrive.io/turbo-bundler/', '_blank');
          }}
        >
          Learn More <LinkUpArrowIcon />
        </button>
      </div>
      <div className="text-high mt-4 text-sm leading-6">
        Turbo is an open-source bundling and upload service that uses Credits
        for uploading permanent data to Arweave. Think of it like topping up a
        gift card balance and conveniently using it whenever you need to.
      </div>

      <div className="text-high mt-4 text-sm leading-6">
        Turbo enables you to:
      </div>

      <div className="mt-2 flex flex-col gap-3">
        <TurboCalloutPanel text="Bring your own web3 wallet." />
        <TurboCalloutPanel text="Pay in fiat or AR (ETH and SOL coming soon)." />
        <TurboCalloutPanel text="Improve upload speed and reliability." />
        <TurboCalloutPanel text="Easily manage your account balance." />
      </div>

      <div className="text-high mt-6 text-sm leading-6">
        Credits can be used with a growing list of turbocharged apps like{' '}
        <a
          className="underline"
          href="https://app.ardrive.io"
          target="_blank"
          rel="noopener noreferer"
          aria-label="ArDrive"
        >
          ArDrive
        </a>{' '}
        or{' '}
        <a
          className="underline"
          href="https://helix.ar.io"
          target="_blank"
          rel="noopener noreferer"
          aria-label="Helix"
        >
          Helix
        </a>
        , and in your own app or workflow using the{' '}
        <a
          className="underline"
          href="https://github.com/ardriveapp/turbo-sdk"
          target="_blank"
          rel="noopener noreferer"
          aria-label="Turbo Toolkit"
        >
          Turbo Toolkit
        </a>
        .
      </div>

      <div className="grow" />
      <div className="mt-10 rounded border border-default text-sm font-semibold text-fg-disabled">
        <div className="flex border-b border-default py-4 pl-6 pr-4 ">
          <div className="flex grow items-center gap-2">
            Current Exchange Rate
            <Tooltip message="The current exchange rate between USD and AR.">
              <InfoIcon />
            </Tooltip>
          </div>
          <button
            onClick={() => {
              refetch();
              refetchFiatToAr();
            }}
          >
            <RefreshIcon />
          </button>
        </div>
        <div className="p-6">
          <div>1 USD equals</div>
          {fiatRates && !isRefetching ? (
            <div className="mt-2 text-base text-fg-muted">
              {(1 / fiatRates.fiat['usd']).toFixed(4)} GB
            </div>
          ) : (
            <Placeholder className="mt-2 h-6" />
          )}
          {fiatToAR && !isRefetchingFiatToAr ? (
            <div className="mt-2 text-base text-fg-muted">
              {(1 / fiatToAR.rate).toFixed(4)} Credits
            </div>
          ) : (
            <Placeholder className="mt-2 h-6" />
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
