import { TokenType, USD } from '@ardrive/turbo-sdk/web';
import { useCallback, useEffect, useState } from 'react';
import { isNumeric } from 'validator';
import Placeholder from './components/Placeholder';
import {
  currencyLabels,
  LINK_HOW_ARE_CONVERSIONS_DETERMINED,
  valueStringError,
} from './constants';
import useAddress from './hooks/useAddress';
import useUploadCostGib from './hooks/useUploadCostGib';
import { LinkUpArrowIcon } from './icons';
import { getPaymentIntent } from './paymentService';
import { useGlobalStore } from './store';
import {
  getAmountByTokenType,
  getTurboBalance,
  getWincForToken,
  wincToCredits,
} from './utils';

const valueStringDefault = '$0 = 0 credits \u{02248} 0 GB';

const BUTTON_VALUES = {
  fiat: [5, 25, 50, 100],
  arweave: [0.5, 1, 5, 10],
  ethereum: [0.01, 0.05, 0.1, 0.25],
  solana: [0.05, 0.1, 0.25, 0.5],
  kyve: [0, 0, 0, 0],
};

type Currency = 'fiat' | 'crypto';

const TopupPanel = () => {
  const [currency, setCurrency] = useState<Currency>('fiat');

  const [creditBalance, setCreditBalance] = useState<number>();
  const [estimatedStorage, setEstimatedStorage] = useState<number>();

  const [customValue, setCustomValue] = useState<string>();
  const [customValueError, setCustomValueError] = useState<string>();
  const [buttonSelected, setButtonSelected] = useState<number>();
  const [valueString, setValueString] = useState<string>(valueStringDefault);
  const [topupValue, setTopupValue] = useState<number>();

  const address = useAddress();

  const setPaymentIntent = useGlobalStore((state) => state.setPaymentIntent);
  const setPaymentAmount = useGlobalStore((state) => state.setPaymentAmount);
  const setCryptoTopupValue = useGlobalStore(
    (state) => state.setCryptoTopupValue,
  );
  const turboUnauthenticatedClient = useGlobalStore(
    (state) => state.turboUnauthenticatedClient,
  );

  const { data: uploadCostGib } = useUploadCostGib(turboUnauthenticatedClient);

  const [paymentIntentError, setPaymentIntentError] = useState<string>();

  const buttonValues =
    currency === 'fiat'
      ? BUTTON_VALUES.fiat
      : BUTTON_VALUES[address?.token || 'arweave'];

  const updateValueStringFiat = useCallback(
    async ({
      fiatCost,
      wincPerGiB,
    }: {
      fiatCost: number;
      wincPerGiB: number;
    }) => {
      if (!turboUnauthenticatedClient || !address) {
        return;
      }

      try {
        const winc = await turboUnauthenticatedClient.getWincForFiat({
          amount: USD(+fiatCost.toFixed(2)),
        });
        const gibs = +winc.winc / wincPerGiB;

        setValueString(
          `$${fiatCost.toFixed(2)} = ${wincToCredits(+winc.winc).toFixed(
            4,
          )} credits \u{02248} ${gibs.toFixed(2)} GiB`,
        );
      } catch (e: unknown) {
        console.error(e);
        setValueString(valueStringError);
      }
    },
    [address, turboUnauthenticatedClient],
  );

  const updateValueStringCrypto = useCallback(
    async ({
      wincPerGiB,
      tokenCost,
      tokenLabel,
      tokenType,
    }: {
      wincPerGiB: number;
      tokenCost: number;
      tokenLabel: string;
      tokenType: TokenType;
    }) => {
      if (!turboUnauthenticatedClient || !address) {
        return;
      }

      const cryptoAmount = getAmountByTokenType(tokenCost, address.token);
      try {
        const winc = await getWincForToken(
          cryptoAmount ? +cryptoAmount : 0,
          tokenType,
        );
        const gibs = +winc.winc / wincPerGiB;

        setValueString(
          `${tokenCost} ${tokenLabel} = ${wincToCredits(+winc.winc).toFixed(
            4,
          )} credits \u{02248} ${gibs.toFixed(2)} GiB`,
        );
      } catch (e: unknown) {
        console.error(e);
        setValueString(valueStringError);
      }
    },
    [address, turboUnauthenticatedClient],
  );

  useEffect(() => {
    if (address) {
      getTurboBalance(address.address, address.token).then((balance) => {
        setCreditBalance(wincToCredits(balance.winc));

        if (uploadCostGib) {
          setEstimatedStorage(balance.winc / Number(uploadCostGib[0].winc));
        }
      });
    } else {
      setCreditBalance(undefined);
    }
  }, [address, uploadCostGib]);

  useEffect(() => {
    if (!uploadCostGib || !address) {
      return;
    }

    const numValue =
      buttonSelected !== undefined
        ? buttonValues[buttonSelected]
        : customValue
          ? +customValue
          : 0;

    if (
      numValue !== undefined &&
      !isNaN(numValue) &&
      (currency == 'crypto' || numValue >= 5)
    ) {
      if (currency == 'fiat') {
        const fiatCost = numValue;
        updateValueStringFiat({
          fiatCost,
          wincPerGiB: Number(uploadCostGib[0].winc),
        });
      } else {
        updateValueStringCrypto({
          wincPerGiB: Number(uploadCostGib[0].winc),
          tokenCost: numValue,
          tokenLabel: currencyLabels[address.token],
          tokenType: address.token,
        });
      }
      setTopupValue(numValue);
    } else {
      setValueString(
        `${currency == 'crypto' ? `0 ${currencyLabels[address.token]} \u{02248} ` : ''}${valueStringDefault}`,
      );
      setTopupValue(undefined);
    }
  }, [
    customValue,
    buttonSelected,
    uploadCostGib,
    buttonValues,
    currency,
    address,
    updateValueStringFiat,
    updateValueStringCrypto,
  ]);

  const isValidCustomFormat = (val: string) => {
    if (currency == 'fiat') {
      return val.length == 0 || isNumeric(val, { no_symbols: true });
    }
    return val.length == 0 || Number(val) >= 0;
  };

  const isValidCustomAmount = (val: string) => {
    if (currency == 'fiat') {
      return (val.length > 0 && Number(val) < 5) || Number(val) > 10000
        ? 'Please enter an amount between $5 and $10,000'
        : undefined;
    }
    return Number(val) <= 0
      ? 'Please enter a value greater than zero.'
      : undefined;
  };

  return (
    <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
      <div className="grid w-full grid-cols-2">
        <div className="flex flex-col items-start">
          <div className="font-bold text-fg-muted">Your Balance</div>
          <div className="text-2xl text-fg-disabled">
            {creditBalance !== undefined ? (
              <>{creditBalance?.toFixed(4)} Credits </>
            ) : (
              <Placeholder className="mt-1 h-6 w-12" />
            )}
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div className="font-bold text-fg-muted">Estimated Storage</div>
          <div className="text-2xl text-fg-disabled">
            {estimatedStorage !== undefined ? (
              <>{`\u{02248} ${estimatedStorage?.toFixed(2)}`} GiB</>
            ) : (
              <Placeholder className="mt-1 h-6 w-12" />
            )}
          </div>
        </div>
      </div>
      <div className="mt-12 font-bold text-fg-muted">Buying Credits</div>
      <div className="text-high mt-3 text-sm">
        Turbo Credits will be added to your wallet and can be used right away.
        Credit purchases are non-refundable and non-transferable.
      </div>
      <div className="text-high mt-6 flex gap-4 text-sm">
        <div>Amount</div>
        {address?.signer && (
          <select
            className="bg-canvas px-2 text-sm text-fg-muted"
            value={currency}
            onChange={(e) => {
              setCurrency(e.target.value as Currency);
              setCustomValue(undefined);
              setButtonSelected(undefined);
              setCustomValueError(undefined);
            }}
          >
            <option value="fiat">USD</option>
            <option value="crypto">{currencyLabels[address.token]}</option>
          </select>
        )}
      </div>
      <div className="mt-3 grid w-full grid-cols-4 gap-3">
        {buttonValues.map((value, index) => (
          <button
            key={index}
            className={`rounded p-2.5 ${
              buttonSelected === index ? 'bg-[#f00]' : 'bg-default'
            }`}
            onClick={() => {
              setCustomValue(undefined);
              setButtonSelected(index);
              setCustomValueError(undefined);
            }}
          >
            {currency == 'fiat' && '$'}
            {value}
          </button>
        ))}
      </div>
      <div className="mt-10 text-sm text-fg-disabled">
        Custom Amount {currency == 'fiat' && <>(min $5 - max $10,000)</>}
      </div>
      <div>
        <input
          type="text"
          className={`mt-4 flex w-full gap-2 rounded border-2 border-default bg-canvas ${currency == 'fiat' ? 'pl-8' : 'pl-4'} py-2 pr-4`}
          value={customValue || ''}
          onChange={(e) => {
            const val = e.target.value;

            if (isValidCustomFormat(val)) {
              setCustomValue(currency == 'fiat' ? Number(val).toString() : val);
              setButtonSelected(undefined);

              setCustomValueError(isValidCustomAmount(val));
            }
          }}
        ></input>
        {currency == 'fiat' && (
          <div className="pointer-events-none relative bottom-[2.125rem] pl-4">
            $
          </div>
        )}
      </div>
      {customValueError && (
        <div className="text-sm text-error">{customValueError}</div>
      )}
      <div className="mt-24 flex w-full flex-col gap-1 border-y border-default py-4">
        <div
          className={`text-sm font-semibold ${valueString === valueStringError ? 'text-error' : 'text-fg-muted'}`}
        >
          {valueString}
        </div>
        <button
          className="flex items-center"
          onClick={() => window.open(LINK_HOW_ARE_CONVERSIONS_DETERMINED)}
        >
          <div className="text-sm text-fg-disabled">
            How are conversions determined?
          </div>
          <LinkUpArrowIcon className="text-fg-disabled" />
        </button>
      </div>
      <div className="mt-8 flex w-full justify-end">
        <button
          disabled={!topupValue || valueString === valueStringError}
          className="w-40 rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
          onClick={async () => {
            if (address?.address && topupValue) {
              if (currency === 'fiat') {
                try {
                  const paymentIntent = await getPaymentIntent(
                    address.address,
                    USD(topupValue).amount,
                    address.token,
                  );
                  if (paymentIntent.paymentSession) {
                    setPaymentIntent(paymentIntent.paymentSession);
                    setPaymentAmount(USD(topupValue).amount);
                  }
                } catch (e: unknown) {
                  setPaymentIntentError((e as Error).message);
                }
              } else {
                setCryptoTopupValue(topupValue!);
              }
            }
          }}
        >
          Next
        </button>
      </div>
      {paymentIntentError && (
        <div className="mt-2 w-full text-right text-sm text-error">
          {paymentIntentError}
        </div>
      )}
    </div>
  );
};

export default TopupPanel;
