import { createConfig, http } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { metaMask } from 'wagmi/connectors';

export const config = createConfig({
  chains: [mainnet],
  connectors: [metaMask({ extensionOnly: true })],
  transports: {
    [mainnet.id]: http(),
  },
});

// export const config = getDefaultConfig({
//   appName: 'turbo-topup',
//   projectId: WALLETCONNECT_PROJECT_ID,
//   chains: [mainnet],
// });
