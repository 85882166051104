import { TurboWincForFiatResponse, USD } from '@ardrive/turbo-sdk/web';
import { useStripe } from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { valueStringError } from './constants';
import useAddress from './hooks/useAddress';
import { RefreshIcon, TurboLogoIcon } from './icons';
import { useGlobalStore } from './store';
import { getWincForFiat, wincToCredits } from './utils';

const ConfirmationPanel = () => {
  const stripe = useStripe();

  const address = useAddress();

  const paymentAmount = useGlobalStore((state) => state.paymentAmount);
  const paymentIntent = useGlobalStore((state) => state.paymentIntent);
  const paymentInformation = useGlobalStore(
    (state) => state.paymentInformation,
  );
  const promoCode = useGlobalStore((state) => state.promoCode);
  const setPaymentIntentResult = useGlobalStore(
    (state) => state.setPaymentIntentResult,
  );
  const setPaymentInformation = useGlobalStore(
    (state) => state.setPaymentInformation,
  );
  const turboUnauthenticatedClient = useGlobalStore(
    (state) => state.turboUnauthenticatedClient,
  );

  const [estimatedCredits, setEstimatedCredits] =
    useState<TurboWincForFiatResponse>();

  const [countdown, setCountdown] = useState<number>(5 * 60);

  const [tosAgreed, setTosAgreed] = useState<boolean>(false);

  const [paymentError, setPaymentError] = useState<string>();

  const [sendingPayment, setSendingPayment] = useState<boolean>(false);

  const formatCountdown = (countdown: number) => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const updateEstimatedCredits = useCallback(async () => {
    if (paymentAmount && turboUnauthenticatedClient && address) {
      try {
        const response = await getWincForFiat({
          amount: USD(paymentAmount / 100),
          promoCode,
          destinationAddress: address.address,
        });
        setEstimatedCredits(response);
      } catch (e: unknown) {
        console.error(e);
        setEstimatedCredits(undefined);
      }
    }
  }, [address, paymentAmount, promoCode, turboUnauthenticatedClient]);

  useEffect(() => {
    updateEstimatedCredits();
  }, [paymentIntent, updateEstimatedCredits]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let c = countdown - 1;
      if (c < 0) {
        c = 5 * 60;
        updateEstimatedCredits();
      }
      setCountdown(c);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  const submitPayment = async () => {
    if (!stripe || !paymentIntent?.client_secret || !paymentInformation) {
      return;
    }

    setSendingPayment(true);

    try {
      const result = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: paymentInformation.paymentMethodId,
          receipt_email: paymentInformation.email,
        },
      );

      if (result.error) {
        console.error(result.error.message);
        setPaymentError(result.error.message);
      } else {
        setPaymentIntentResult(result);
      }
    } finally {
      setSendingPayment(false);
    }
  };

  // TODO: remove hack to deal with actualPaymentAmount missing from TurboWincForFiatResponse
  // once turbo-sdk is updated
  const actualPaymentAmount = estimatedCredits
    ? (estimatedCredits as unknown as { actualPaymentAmount: number })
        .actualPaymentAmount / 100
    : 0;
  const paymentAmountUSD = paymentAmount ? paymentAmount / 100 : 0;

  return (
    <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
      <div>
        <div className="font-bold text-fg-muted">Review</div>
      </div>

      <div className="mt-8 w-full bg-black p-6">
        <TurboLogoIcon className="w-12" />
        {estimatedCredits ? (
          <>
            <div className="flex w-full flex-col items-center py-4">
              <div className="text-4xl font-bold text-fg-muted">
                {wincToCredits(Number(estimatedCredits?.winc) || 0).toFixed(4)}
              </div>
              <div className="text-sm text-fg-disabled">Credits</div>
            </div>
            {paymentAmount && actualPaymentAmount !== paymentAmountUSD && (
              <>
                <div className="flex w-full border-t border-default py-2 text-sm text-fg-disabled">
                  <div>Subtotal:</div>
                  <div className="grow text-right">
                    ${paymentAmountUSD.toFixed(2)}
                  </div>
                </div>
                <div className="flex w-full border-t border-default py-2 text-sm text-fg-disabled">
                  <div>Discount:</div>
                  <div className="grow text-right">
                    -$
                    {Math.abs(actualPaymentAmount - paymentAmountUSD).toFixed(
                      2,
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="flex w-full border-t border-default pt-4 text-sm text-fg-muted">
              <div>Total:</div>
              <div className="grow text-right">
                ${actualPaymentAmount.toFixed(2)}
              </div>
            </div>
          </>
        ) : (
          <div className="flex w-full flex-col items-center py-4">
            <div className="center text-xl font-bold text-error">
              {valueStringError}
            </div>
          </div>
        )}
      </div>

      <div className="flex w-full  items-center bg-surface px-6 py-2.5 text-center text-sm  text-fg-disabled">
        <div className="grow text-left">
          Quote Updates in{' '}
          <span className="text-fg-muted">{formatCountdown(countdown)}</span>
        </div>
        <button
          className="flex items-center gap-1 text-right"
          onClick={() => {
            setCountdown(5 * 60);
            updateEstimatedCredits();
          }}
        >
          <RefreshIcon /> Refresh
        </button>
      </div>

      <div className="mb-8 mt-6 flex items-center">
        <input
          type="checkbox"
          id="tosCheckbox"
          className="mr-2"
          checked={tosAgreed}
          onChange={(e) => setTosAgreed(Boolean(e.target.checked))}
        ></input>
        <label htmlFor="tosCheckbox" className="text-sm text-fg-disabled">
          I agree to the{' '}
          <a
            href="https://ardrive.io/tos-and-privacy/"
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="ArDrive terms of service and privacy policy"
          >
            ArDrive terms of service and privacy policy
          </a>
          .
        </label>
      </div>

      <div className="flex w-full justify-end border-t border-default pt-8">
        <button
          className="text-sm text-fg-disabled"
          onClick={() => setPaymentInformation(undefined)}
        >
          Back
        </button>
        <div className="grow"></div>
        <button
          disabled={!tosAgreed || sendingPayment || !estimatedCredits}
          className="w-40 rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
          onClick={submitPayment}
        >
          Pay
        </button>
      </div>
      {paymentError && (
        <div className="mt-2 w-full text-right text-sm text-error">
          {paymentError}
        </div>
      )}
    </div>
  );
};

export default ConfirmationPanel;
