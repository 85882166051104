import { TokenType } from '@ardrive/turbo-sdk/web';
import { loadStripe, PaymentIntent } from '@stripe/stripe-js';
import { PAYMENT_SERVICE_FQDN, STRIPE_PUBLISHABLE_KEY } from './constants';

export const STRIPE_PROMISE = loadStripe(STRIPE_PUBLISHABLE_KEY);
export const getPaymentIntent = async (
  address: string,
  amount: number,
  token: TokenType,
  promoCode?: string,
) => {
  const url = `https://${PAYMENT_SERVICE_FQDN}/v1/top-up/payment-intent/${address}/usd/${amount}`;

  const queryStringValues = {
    token,
    ...(promoCode && { promoCode }),
  };

  const queryString = `?${new URLSearchParams(queryStringValues).toString()}`;

  const res = await fetch(url.concat(queryString));

  if (res.status !== 200) {
    console.error(res);
    throw new Error('Error connecting to server. Please try again later.');
  }
  return res.json() as Promise<{
    topUpQuote: { quotedPaymentAmount: number };
    paymentSession: PaymentIntent;
  }>;
};
