import { useCallback, useEffect, useState } from 'react';
import Placeholder from './components/Placeholder';
import { currencyLabels, valueStringError } from './constants';
import useAddress from './hooks/useAddress';
import { RefreshIcon, TurboLogoIcon } from './icons';
import BlockingMessageModal from './modals/BlockingMessageModal';
import { useGlobalStore } from './store';
import { getAmountByTokenType, getWincForToken, wincToCredits } from './utils';

const CryptoConfirmationPanel = () => {
  const address = useAddress();
  const cryptoTopupValue = useGlobalStore((state) => state.cryptoTopupValue);
  const setCryptoTopupValue = useGlobalStore(
    (state) => state.setCryptoTopupValue,
  );
  const setCryptoTopupResponse = useGlobalStore(
    (state) => state.setCryptoTopupResponse,
  );

  const turboAuthenticatedClient = useGlobalStore(
    (state) => state.turboAuthenticatedClient,
  );
  const turboUnauthenticatedClient = useGlobalStore(
    (state) => state.turboUnauthenticatedClient,
  );

  const [estimatedCredits, setEstimatedCredits] = useState<string>();

  const [countdown, setCountdown] = useState<number>(5 * 60);

  const [tosAgreed, setTosAgreed] = useState<boolean>(false);

  const [paymentError, setPaymentError] = useState<string>();

  const [showSigningMessage, setShowSigningMessage] = useState<boolean>(false);

  const formatCountdown = (countdown: number) => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const updateEstimatedCredits = useCallback(async () => {
    if (turboUnauthenticatedClient && address && cryptoTopupValue) {
      try {
        const cryptoAmount = getAmountByTokenType(
          cryptoTopupValue,
          address.token,
        );
        const winc = await getWincForToken(
          cryptoAmount ? +cryptoAmount : 0,
          address.token,
        );
        setEstimatedCredits(wincToCredits(+winc.winc).toFixed(4));
      } catch (e: unknown) {
        console.error(e);
        setEstimatedCredits(valueStringError);
      }
    }
  }, [address, cryptoTopupValue, turboUnauthenticatedClient]);

  useEffect(() => {
    updateEstimatedCredits();
  }, [updateEstimatedCredits]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let c = countdown - 1;
      if (c < 0) {
        c = 5 * 60;
        updateEstimatedCredits();
      }
      setCountdown(c);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  const submitPayment = async () => {
    if (!address || !turboAuthenticatedClient || !cryptoTopupValue) {
      return;
    }

    setPaymentError(undefined);
    setShowSigningMessage(true);

    const amount = getAmountByTokenType(cryptoTopupValue, address.token);
    if (!amount) {
      setPaymentError('Invalid token type');
      setShowSigningMessage(false);
      return;
    }
    try {
      const results = await turboAuthenticatedClient.topUpWithTokens({
        tokenAmount: amount,
        // feeMultiplier: 1.1, // 10% increase in reward for improved mining chances
      });
      setCryptoTopupResponse(results);
    } catch (e: unknown) {
      console.error(e);
      setPaymentError(
        'Unable to complete transaction. Please review and try again later.',
      );
    } finally {
      setShowSigningMessage(false);
    }
  };

  return (
    <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
      <div>
        <div className="font-bold text-fg-muted">Review</div>
      </div>

      <div className="mt-8 w-full bg-black p-6">
        <TurboLogoIcon className="w-12" />
        <div className="flex w-full flex-col items-center py-4 text-center">
          {estimatedCredits === valueStringError ? (
            <div className="text-xl font-bold text-error">
              {estimatedCredits}
            </div>
          ) : (
            <>
              <div className="text-4xl font-bold text-fg-muted">
                {estimatedCredits}
              </div>
              <div className="text-sm text-fg-disabled">Credits</div>
            </>
          )}
        </div>
        <div className="flex w-full border-t border-default pt-4 text-sm text-fg-muted">
          <div>Total:</div>
          <div className="grow text-right">
            {address && cryptoTopupValue ? (
              `${cryptoTopupValue} ${currencyLabels[address.token]}`
            ) : (
              <Placeholder />
            )}
          </div>
        </div>
      </div>

      <div className="flex w-full  items-center bg-surface px-6 py-2.5 text-center text-sm  text-fg-disabled">
        <div className="grow text-left">
          Quote Updates in{' '}
          <span className="text-fg-muted">{formatCountdown(countdown)}</span>
        </div>
        <button
          className="flex items-center gap-1 text-right"
          onClick={() => {
            setCountdown(5 * 60);
            updateEstimatedCredits();
          }}
        >
          <RefreshIcon /> Refresh
        </button>
      </div>

      <div className="mt-8 w-full text-sm text-fg-disabled">
        Crypto topups usually take less than an hour to process and for credit
        balances to update.
      </div>

      <div className="mb-8 mt-6 flex items-center">
        <input
          type="checkbox"
          id="tosCheckbox"
          className="mr-2"
          checked={tosAgreed}
          onChange={(e) => setTosAgreed(Boolean(e.target.checked))}
        ></input>
        <label htmlFor="tosCheckbox" className="text-sm text-fg-disabled">
          I agree to the{' '}
          <a
            href="https://ardrive.io/tos-and-privacy/"
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="ArDrive terms of service and privacy policy"
          >
            ArDrive terms of service and privacy policy
          </a>
          .
        </label>
      </div>

      <div className="flex w-full justify-end border-t border-default pt-8">
        <button
          className="text-sm text-fg-disabled"
          onClick={() => setCryptoTopupValue(undefined)}
        >
          Back
        </button>
        <div className="grow"></div>
        <button
          disabled={!tosAgreed || estimatedCredits === valueStringError}
          className="w-40 rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
          onClick={submitPayment}
        >
          Pay
        </button>
      </div>
      {paymentError && (
        <div className="mt-2 w-full text-right text-sm text-error">
          {paymentError}
        </div>
      )}
      {showSigningMessage && (
        <BlockingMessageModal
          onClose={() => {
            setShowSigningMessage(false);
          }}
          message="Sign transaction to complete purchase..."
        />
      )}
    </div>
  );
};

export default CryptoConfirmationPanel;
