import 'arconnect';
import { FC, ReactNode, useEffect } from 'react';
import { useGlobalStore } from '../store';

const ArConnectProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const setArConnectInfo = useGlobalStore((state) => state.setArConnectInfo);

  useEffect(() => {
    const walletLoaded = async () => {
      if (!window.arweaveWallet) {
        return;
      }
      const permissions = await window.arweaveWallet.getPermissions();

      if (permissions.includes('ACCESS_ADDRESS')) {
        const address = await window.arweaveWallet.getActiveAddress();
        setArConnectInfo({ address });
      }
    };

    walletLoaded();

    const walletSwitched = async (e: CustomEvent<{ address: string }>) => {
      const address = e.detail.address;
      setArConnectInfo({ address });
    };

    window.addEventListener('arweaveWalletLoaded', walletLoaded);
    window.addEventListener(
      'walletSwitch',
      walletSwitched as unknown as EventListener,
    );

    return () => {
      window.removeEventListener('arweaveWalletLoaded', walletLoaded);
      window.removeEventListener(
        'walletSwitch',
        walletSwitched as unknown as EventListener,
      );
    };
  });

  return children;
};

export default ArConnectProvider;
