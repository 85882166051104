import { Dialog, DialogPanel } from '@headlessui/react';
import { ReactElement } from 'react';
import { CloseIcon } from '../icons';

const BaseModal = ({
  onClose,
  children,
  showCloseButton = true,
  useDefaultPadding = true,
}: {
  onClose: () => void;
  children: ReactElement;
  showCloseButton?: boolean;
  useDefaultPadding?: boolean;
}) => {
  return (
    <Dialog
      open={true}
      onClose={() => {}}
      className="relative z-10 text-fg-muted "
    >
      <div className="fixed inset-0 w-screen bg-black/90" aria-hidden="true" />

      <div className="fixed inset-0 flex  w-screen items-center justify-center p-4">
        <DialogPanel
          className={`relative flex max-h-full flex-col items-stretch rounded border border-default bg-canvas ${
            useDefaultPadding ? 'p-6' : ''
          } text-center text-white`}
        >
          {showCloseButton && (
            <div className="flex justify-end">
              <button onClick={onClose}>
                <CloseIcon className="size-5" />
              </button>
            </div>
          )}
          <div className="scrollbar flex grow flex-col overflow-y-auto">
            {children}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default BaseModal;
