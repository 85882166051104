import { useEffect } from 'react';
import ConfirmationPanel from './ConfirmationPanel';
import CryptoConfirmationPanel from './CryptoConfirmationPanel';
import CryptoTopupComplete from './CryptoTopupComplete';
import FiatTopupComplete from './FiatTopupComplete';
import useAddress from './hooks/useAddress';
import InfoPanel from './InfoPanel';
import PaymentPanel from './PaymentPanel';
import { useGlobalStore } from './store';
import TopupPanel from './TopupPanel';

const TopupPage = () => {
  const paymentIntent = useGlobalStore((state) => state.paymentIntent);
  const paymentInformation = useGlobalStore(
    (state) => state.paymentInformation,
  );
  const paymentIntentResult = useGlobalStore(
    (state) => state.paymentIntentResult,
  );
  const cryptoTopupValue = useGlobalStore((state) => state.cryptoTopupValue);
  const cryptoTopupResponse = useGlobalStore(
    (state) => state.cryptoTopupResponse,
  );

  const clearAllPaymentState = useGlobalStore(
    (state) => state.clearAllPaymentState,
  );

  const address = useAddress();

  useEffect(() => {
    clearAllPaymentState();
  }, [address?.address, clearAllPaymentState]);

  return (
    <div>
      <div className="grid size-full grid-cols-2 items-center rounded border border-default bg-canvas">
        {cryptoTopupResponse ? (
          <CryptoTopupComplete />
        ) : paymentIntentResult ? (
          <FiatTopupComplete />
        ) : cryptoTopupValue ? (
          <CryptoConfirmationPanel />
        ) : paymentInformation && paymentIntent ? (
          <ConfirmationPanel />
        ) : paymentIntent ? (
          <PaymentPanel />
        ) : (
          <TopupPanel />
        )}
        <InfoPanel />
      </div>
      <div className="text-link mt-6 flex w-full justify-center text-sm">
        <div className="grid grid-cols-3 items-center gap-4">
          <a
            href="mailto:support@ardrive.io"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Email ArDrive Support"
          >
            Support
          </a>
          <a
            href="https://ardrive.io/tos-and-privacy"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Terms and conditions"
          >
            Terms and conditions
          </a>
          <a
            href="https://docs.ardrive.io"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Docs"
          >
            Docs
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopupPage;
